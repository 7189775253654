import Vue from 'vue';

/**
 * 下拉框请求模板
 */

import './httpRequest';

const api = Vue.prototype.$http;
// 公司下拉框
export const getCompanyList = () => {
  return api({
    url: `/tc/company/combo`,
    method: 'get',
  });
};
// 时段下拉框
export const getPeriodList = (isUse) => {
  return api({
    url: `/cc/global-period/all`,
    method: 'get',
    params: { isUse: isUse },
  });
};

// 智能取餐柜下拉框款
export const getFreezerList = (isUse, isGroup) => {
  return api({
    url: `/cc/freezer/all`,
    method: 'get',
    params: { isUse: isUse, isGroup: isGroup },
  });
};

// 供应商下拉框
export const getOrgList = (type, isUse) => {
  return api({
    url: `/cc/org/all`,
    method: 'get',
    params: { type: type, isUse: isUse },
  });
};

// 合同供应商
export const getContractOrgList = () => {
  return api({
    url: `/tc/contract/select`,
    method: 'get',
    params: {},
  });
};

// 区域下拉框
export const getZoneList = () => {
  return api({
    url: `/os/zone/select`,
    method: 'get',
  });
};

// 公司部门级联框
export const getDepartmentList = () => {
  return api({
    url: `/tc/companydepartment/combo`,
    method: 'get',
  });
};

// 配送员下拉框
export const getDeliveryList = () => {
  return api({
    url: `/delivery/deliveryuser/combo`,
    method: 'get',
  });
};

// 补助方式下拉框
export const getSubsidyList = () => {
  return api({
    url: `/tc/subsidy/combo`,
    method: 'get',
  });
};

// 员工下级联框
export const getEmployeesList = () => {
  return api({
    url: `/tc/employees/combo`,
    method: 'get',
  });
};

// 用户下拉框
export const getUserList = () => {
  return api({
    url: `/sys/user/queryAll`,
    method: 'get',
  });
};

// 代理商&供销商级联框
export const getAgentList = () => {
  return api({
    url: `/cc/org/agency-and-distributor:all`,
    method: 'get',
  });
};

// 公司规模下拉框
export const getCompanySizeList = () => {
  return api({
    url: `/tc/companySize/select`,
    method: 'get',
  });
};

// 智能取餐柜机器码下拉框
export const getMachineCodeList = () => {
  return api({
    url: `/cc/freezer-device-info/all`,
    method: 'get',
  });
};
// 智能取餐柜规格下拉框
export const getSpecList = () => {
  return api({
    url: `/cc/freezer-spec/all`,
    method: 'get',
  });
};
// 供应商商品分类下拉框
export const getCategoryList = (orgId) => {
  return api({
    url: `/cc/category/page`,
    method: 'get',
    params: {
      orgId: orgId,
      perPage: -1,
      orderDir: 'asc',
    },
  });
};
// 供应商商品下拉框
export const getGoodsAndPackage = (orgId) => {
  return api({
    url: `/tc/goods/combo`,
    method: 'get',
    params: {
      orgId: orgId,
    },
  });
};
// 优惠券下拉框
export const getCouponList = (orgId) => {
  return api({
    url: `/tc/coupon/select/${orgId}`,
    method: 'get',
  });
};

export const getOrgPeriodList = (orgId) => {
  return api({
    url: `/tc/orgperiod/combo/${orgId}`,
    method: 'get',
  });
};

export const getRechargeRuleList = () => {
  return api({
    url: `/sys/rechargerule/combo`,
    method: 'get',
  });
};
